/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@font-face {
  font-family: "SVNGothamBook";
  src: url("/assets/fonts/SVN-Gotham Bold.otf");
}

@font-face {
  font-family: "GothamBook";
  src: url("/assets/fonts/GothamBook.otf");
}

tr.detail-row {
  height: 0 !important;
}

.element-row td {
  border-bottom-width: 0;
}

.element-detail {
  overflow: hidden;
  display: flex;
}

// Quality
.label-common {
  background-color: #ffffff;
  color: #000000;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-uncommon {
  background-color: #1eff00;
  color: #ffffff;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-rare {
  background-color: #0070dd;
  color: #ffffff;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-epic {
  background-color: #a335ee;
  color: #ffffff;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-legendary {
  background-color: #ff8000;
  color: #ffffff;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-artifact {
  background-color: #e6cc80;
  color: #000000;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

// End of Quality

.mat-placeholder-required.mat-form-field-required-marker {
  color: red !important;
}

.required::after {
  content: "*";
  color: red;
  margin-left: 3px;
}

.badge-type {
  min-width: 22px;
  min-height: 22px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  display: inline-grid;
  border-radius: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  margin-right: 6px;
}

.bg-brand {
  background-color: rgba(234, 179, 8, 0.3);
}

.bg-supp {
  background-color: rgba(22, 163, 74, 0.3);
}

.remove-spinner {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}